
import Vue, { PropType } from "vue";
import { DialogCloseType, DialogI } from "@/types/dialog";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import TextField from "@/components/form/inputs/TextField.vue";

export default Vue.extend({
  name: "MediaRenameFileDialog",
  components: {
    BaseDialog,
    TextField,
  },
  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },
  data: () => ({
    name: "",
  }),
  methods: {
    submitForm() {
      this.dialog.close({
        type: DialogCloseType.SUBMITTED,
        payload: {
          name: this.name,
        },
      });
    },
  },
});
